$(document).foundation({
    tab: {
      callback : function (tab) {
        console.log(tab);
      }
    }
  });
$(document).ready(function() {
  ("use strict");

  // -----------
  // Mobile menu
  // -----------

  var handleMatchMedia = function(mediaQuery) {
      // ≤ 1023px
      if (mediaQuery.matches) {
        // crear el botó del menú
        // $('.main-nav').prepend('<button class="hamburger hamburger--3dx menu-button" aria-expanded="false" aria-label="Menú" id="nav"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="menu-button__label vh">Menú</span></button>')
        // ocultar el menú
        $(".menu-wrapper")
          .attr("hidden", "")
          .addClass("js-hidden");
        // clicar el botó
        $(".main-nav button").on("click", function() {
          // afegir classe al botó
          $(this).toggleClass("is-active");
          // afegir classe al body
          $("body").toggleClass("js-menu-open");
          // canviar atributs del botó i del menú
          if ($(this).attr("aria-expanded") == "true") {
            $(this).attr("aria-expanded", "false");
            $(".menu-wrapper")
              .attr("hidden", "")
              .addClass("js-hidden");
          } else {
            $(this).attr("aria-expanded", "true");
            $(".menu-wrapper")
              .removeAttr("hidden")
              .removeClass("js-hidden");
          }
        });
      } else {
        // ≥ 1024px
        // treure el botó
        // $('.main-nav button').remove();
        // mostrar el menú
        $(".menu-wrapper")
          .removeAttr("hidden")
          .removeClass("js-hidden");
        // treure classe
        $("body").removeClass("js-menu-open");
      }
    },
    mq = window.matchMedia("only screen and (max-width: 1023px)");
  handleMatchMedia(mq);
  mq.addListener(handleMatchMedia);

  // --------
  // Submenús
  // --------

  // http://heydonworks.com/practical_aria_examples/#submenus
  $(".main-nav ul ul")
    .prev("a")
    .attr("aria-haspopup", "true");
  // .append('<span aria-hidden="true"> &#x25be;</span>');

  var showSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "false");
  };

  var hideSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "true");
  };

  $(".with-dropdowns > li > a").on("focus", function(e) {
    hideSubmenu($('[aria-label="submenu"]'));
  });

  $("[aria-haspopup]").on("click", function(e) {
    var submenu = $(this).next();
    showSubmenu(submenu);
    //$(submenu).find('li:first-child a').focus();
    return false;
  });

  $("[aria-haspopup]").hover(function() {
    showSubmenu($(this).next());
    $(this).off("click");
  });

  $("[aria-haspopup]")
    .parents("li")
    .mouseleave(function() {
      hideSubmenu($(this).find('[aria-label="submenu"]'));
    });

  // -------
  // sub-nav
  // -------
  $(".sub-nav li").on("mouseenter", function() {
    $(this).addClass("current");
  });
  $(".sub-nav li").on("mouseleave", function() {
    $(this).removeClass("current");
  });


   // home counters
   /*$('.counter-value').each(function () {
    $(this).prop('Counter',0).animate({
        Counter: $(this).text()
    }, {
        duration: 4000,
        easing: 'swing',
        step: function (now) {
            $(this).text(Math.ceil(now));
        }
    });
  });*/
  // ----------
  // feature.js
  // ----------

  if (feature.svg) {
    $("html").addClass("svg");
  }

  // --------------------------------------------------------------------------------------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // --------------------------------------------------------------------------------------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function(event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function() {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // -----------------
  // Canvi svg per png
  // -----------------

  if (!feature.svg) {
    var imgs = document.getElementsByTagName("img");
    var endsWithDotSvg = /.*\.svg$/;
    var i = 0;
    var l = imgs.length;
    for (; i !== l; ++i) {
      if (imgs[i].src.match(endsWithDotSvg)) {
        imgs[i].src = imgs[i].src.slice(0, -3) + "png";
      }
    }
  }

  // -----------------
  // Smooth scroll top
  // -----------------

  if (window.matchMedia("(min-width: 64em)").matches) {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
        $(".top").fadeIn();
      } else {
        $(".top").fadeOut();
      }
    });
    $(function() {
      $(
        'a[href*="#"]:not([href="#"],[href="#menu"],[href="#login-popup"],[href="#proyecto"],[href="#alimentacion"],.open-form a)'
      ).click(function() {
        if (
          location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          if (target.length) {
            $("html, body").animate(
              {
                scrollTop: target.offset().top
              },
              1000
            );
            location.hash = this.hash.slice(1);
            return false;
          }
        }
      });
    });
  }

  // -------------
  // Sticky header
  // -------------

  var handleMatchMedia2 = function(mediaQuery) {
      // ≥ 1024px
      if (mediaQuery.matches) {
        var lastScrollTop = 200;
        $(window).scroll(function(event) {
          var st = $(this).scrollTop();
          var hh = $(".main-header").outerHeight();
          if (st > lastScrollTop && st > 100) {
            $(".main-header")
              .addClass("sticky")
              .css("top", -hh + "px");
          } else {
            $(".main-header")
              .removeClass("sticky")
              .removeAttr("style");
          }
          lastScrollTop = st;
        });
      }
    },
    mq2 = window.matchMedia("only screen and (min-width: 1024px)");
  handleMatchMedia2(mq2);
  mq2.addListener(handleMatchMedia2);

  // -------------
  // Scroll reveal
  // -------------
  if (!$("html").hasClass("ie9")) {
    window.sr = ScrollReveal();
    if ($(".reveal").length) {
      sr.reveal(".reveal", {
        reset: true,
        viewFactor: 0.5,
        distance: "80px"
      });
    }
    if ($(".reveal2").length) {
      sr.reveal(".reveal2", {
        viewFactor: 0.5,
        distance: "80px",
        rotate: {
          x: 0,
          y: 0,
          z: 180
        }
      });
    }
    if ($(".reveal3").length) {
      sr.reveal(".reveal3", {
        viewFactor: 0.5,
        distance: "80px"
      });
    }
  }

  // ------------
  // Match height
  // ------------

  $(".h-video").matchHeight();
  $(".panel-concurs").matchHeight();
  $(".materials-list .panel-dash").matchHeight();
  $(".links a").matchHeight();
  $(".buttons a").matchHeight();
  $(".obj").matchHeight();
  $(".grid li a").matchHeight();

  // --------------
  // Toggle idiomes
  // --------------

  $(".lang-toggle").click(function(event) {
    event.stopPropagation();
    $(this).toggleClass("close");
    $(this)
      .next()
      .toggleClass("is-hidden");
  });
  $(document).click(function() {
    $(".lang-toggle")
      .next()
      .addClass("is-hidden");
  });

  // -------------
  // Toggle usuari
  // -------------

  $(".usr-toggle").click(function(event) {
    event.stopPropagation();
    $(this).toggleClass("close");
    $(this)
      .next()
      .toggleClass("is-hidden");
  });
  $(document).click(function() {
    $(".usr-toggle")
      .next()
      .addClass("is-hidden");
  });

  // -------
  // Sliders
  // -------

  $(".slider").slick({
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 12000
  });

  $(".slider-galeria").slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  // -----
  // Fases
  // -----

  $(".fase--1 .fase--num").on("mouseenter", function() {
    $(".fase--1").addClass("active");
  });
  $(".fase--1 .fase--num").on("mouseleave", function() {
    $(".fase--1").removeClass("active");
  });

  $(".fase--2 .fase--num").on("mouseenter", function() {
    $(".fase--2").addClass("active");
  });
  $(".fase--2 .fase--num").on("mouseleave", function() {
    $(".fase--2").removeClass("active");
  });

  $(".fase--3 .fase--num").on("mouseenter", function() {
    $(".fase--3").addClass("active");
  });
  $(".fase--3 .fase--num").on("mouseleave", function() {
    $(".fase--3").removeClass("active");
  });

  $(".fase--4 .fase--num").on("mouseenter", function() {
    $(".fase--4").addClass("active");
  });
  $(".fase--4 .fase--num").on("mouseleave", function() {
    $(".fase--4").removeClass("active");
  });

  // -------------------
  // setmanes saludables
  // -------------------

  if(location.hash == '#rec1') {
    $('#rec1').addClass('destaca');
  };
  if(location.hash == '#rec2') {
    $('#rec2').addClass('destaca');
  };
  if(location.hash == '#rec3') {
    $('#rec3').addClass('destaca');
  };

  // ---------
  // Materials
  // ---------

  $(".material__item").on("mouseenter", function() {
    $(this)
      .parent()
      .find(".material__info")
      .toggleClass("active");
  });
  $(".material__item").on("mouseleave", function() {
    $(this)
      .find(".material__info")
      .removeClass("active");
  });

  /*$(".desc_hover").click(function() {
    $(this)
      .parent()
      .find(".material__info")
      .toggleClass("active");
  });
  $(".desc_hover").click(function() {
    $(this)
      .find(".material__info")
      .removeClass("active");
  });*/

  // -----
  // Login
  // -----

  $("a[href='#login-popup']").magnificPopup({
    type: "inline",
    closeMarkup:
      '<button title="%title%" type="button" class="mfp-close"></button>',
    midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
  });


  // -----
  // LoginError
  // -----
  if(location.hash == '#loginError'){
     $("a[href='#login-popup']").trigger('click');
     $('#formlogin legend').text('Ha habido un error al intentar acceder, inténtelo de nuevo.');
  }

  // -----
  // LostPassword
  // -----
  if(location.hash == '#lostpasswordOk'){
     $("a[href='#login-popup']").trigger('click');
     $('#formcont legend').text('Le hemos enviado un correo con su nueva contraseña.');
  }
  if(location.hash == '#lostpasswordError'){
     $("a[href='#login-popup']").trigger('click');
     $('#formcont legend').text('Ha habido un error, inténtelo de nuevo.');
  }

  // -------------
  // Search escola
  // -------------

  $(".open-form a").on("click", function() {
    $("#formEscola").removeClass("js-hidden");
    return false;
  });

  // -------------
  // Popup
  // -------------

  $(".recover a").on("click", function() {
    $("#formcont").removeClass("js-hidden");
    return false;
  });

  // -------------
  // Afegir classe
  // -------------

/*  $(".js-afegirClasse").on("click", function() {
    $("fieldset.js-hidden").removeClass("js-hidden");
    return false;
  });*/

  if ($(".js-afegirClasse").length > 0) {
    var baseClasse = $(".classes fieldset").clone();
    var index = 1;
    $(".js-afegirClasse").on("click", function() {
      var newClasse = baseClasse.clone();
      index++;
      newClasse.find("[name]").each(function() {
            $(this).attr("name", $(this).attr("name").replace( /\[\d\]/ , "[" + index + "]"));
        });
      newClasse.find("[for]").each(function() {
            $(this).attr("for", $(this).attr("for").replace( /_\d/ , "_" + index));
        });
      newClasse.find("[id]").each(function() {
            $(this).attr("id", $(this).attr("id").replace( /_\d/ , "_" + index));
        });
      $(".classes").append(newClasse);

      return false;
    });

  }





  // -----------
  // Collapsible
  // -----------

  $(".collapsible__header.js-toggle").on("click", function() {
    $(this)
      .toggleClass("active")
      .next()
      .toggleClass("js-hidden");
    return false;
  });


  var autocomplete = $('input[data-autocomplete]');
  if (autocomplete.length > 0) {
    var type = autocomplete.data('autocomplete');
    var options = {
        url: function(search) {
            return "/media/php/escoles.php?"+type+"&search="+search;
        },
        getValue: "nom_centre",
        template: {
            type: "custom",
            method: function(value, item) {
                return item.nom_centre+', <small>'+item.poblacio+', '+item.cp+', '+item.adreca+'</small>';
            }
        },
        list: {
            maxNumberOfElements: 100,
            onClickEvent: function() {
                var info = autocomplete.getSelectedItemData();
                $('#formEscola').show();
                $('#formEscola input[name="escola"]').val(info.id);
                $('#formEscola input[name="nom_centre"]').val(info.nom_centre);
                $('#formEscola input[name="adreca"]').val(info.adreca);
                $('#formEscola input[name="cp"]').val(info.cp);
                //$('#formEscola select[name="cp"]').val(info.cp);
                $('#formEscola input[name="poblacio"]').val(info.poblacio);
                $('#formEscola input[name="email"]').val(info.email);
                $('#formEscola input[name="provincia"]').val(info.provincia);
                $('#formEscola input[name="telefon"]').val(info.telefon);
                $('#formEscola input[id="escola-prof_1"]').val(info.id);

              var target = $('#formEscola');
              if (target.length) {
                $("html, body").animate(
                  {
                    scrollTop: target.offset().top - 20
                  },
                  1000
                );
              }
            }
        }
    };

    autocomplete.easyAutocomplete(options);
  }


  var autocompleteBotiga = $('input[data-autocompletebotiga]');
  if (autocompleteBotiga.length > 0) {
    var type = autocompleteBotiga.data('autocompletebotiga');
    var optionsBotiga = {

        url: function(search) {
            return "/media/php/botigues.php?"+type+"&search="+search;
        },
        getValue: function(element) {
            return element.adreca+', '+element.poblacio;
        },
        template: {
            type: "custom",
            method: function(value, item) {
                return item.adreca+', <small>'+item.poblacio+', '+item.cp+'</small>';
            }
        },
        list: {
            maxNumberOfElements: 100,
            onClickEvent: function() {
                var info = autocompleteBotiga.getSelectedItemData();
                var form = autocompleteBotiga.closest('form');
                $('#lastExtra').val(info.id);
                form.submit();
            }
        }
    };

    autocompleteBotiga.easyAutocomplete(optionsBotiga);
  }



    $('.collapsible').each(function(){
        $(this).on('click', '.cal tbody tr.active td a, .cal tbody tr.active td', function(event){
            event.preventDefault();
            link = $(this);
            if ($(this).find('a').length > 0) {
                link = $(this).find('a').first();
            }
            $(this).closest('table').find('tr.selected').removeClass('selected');
            $(this).closest('tr').addClass('selected');
            $(this).closest('div.week-chosen').find('input[type="hidden"]').val(link.data('time'));
            $(this).closest('div.week-chosen').find('h4 span').text(link.data('timetext'));

            var buttonState = true;
            $(this).closest('form').find('div.week-chosen input[type="hidden"]').each(function(){
                if ($(this).val() == '') {
                    buttonState = false;
                }
            });
            if ($(this).closest('form').find('input[name="classe[]"]:checked').length == 0) {
                buttonState = false;
            }
            $(this).closest('form').find('button').prop('disabled', !buttonState);

            return false;
        });

        $(this).on('change', 'input[name="classe[]"]', function(event){
            event.preventDefault();

            var buttonState = true;
            $(this).closest('form').find('div.week-chosen input[type="hidden"]').each(function(){
                if ($(this).val() == '') {
                    buttonState = false;
                }
            });
            if ($(this).closest('form').find('input[name="classe[]"]:checked').length == 0) {
                buttonState = false;
            }
            $(this).closest('form').find('button').prop('disabled', !buttonState);

            return false;
        });

        $(this).on('click', '.cal caption a', function(event){
            event.preventDefault();
            var calendar = $(this).closest('div');
            var month = $(this).data('month');
            var type = $(this).closest('table').data('type');
            var shop = $(this).closest('form').find('input[name="shop"]').val();
            var lang = $('html').attr('lang');
            $.get( "/media/php/calendar.php", { shop: shop, month: month, type: type, lang: lang }, function(data) {
                calendar.html(data);
                //actualitzar selecció
                var week = calendar.closest('div.week-chosen').find('input[type="hidden"]').val();
                calendar.find('[data-time="'+week+'"]').closest('tr').addClass('selected');
            });
            return false;
        });
    });


    var logDownload = $('a[data-logdownload]');
    if (logDownload.length > 0) {
        logDownload.bind('click', function(){
            var file = $(this).attr('href');
            $.ajax({
              type: "POST",
              url: '/media/php/download.php',
              data: {
                file: file
                },
            });
        });
    }
});
